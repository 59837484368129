

























import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'SignIn',
  data() {
    return {
      reasons: ['AppBenefitReporting', 'AppBenefitAccess'],
    };
  },
  computed: {
    ...mapState(['config']),
  },
  methods: {
    signIn() {
      this.$auth.federatedSignIn();
    },
  },
});
